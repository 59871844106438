.ant-checkbox-checked .ant-checkbox-inner {
  background-color: theme('colors.colorPrimary');
  border-color: theme('colors.colorPrimary');
}

.ant-checkbox .ant-checkbox-inner {
  border-color: theme('colors.colorPrimary');
}

.ant-radio-checked .ant-radio-inner {
  border-color: theme('colors.colorPrimary') !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: theme('colors.colorPrimary');
}

.ant-radio .ant-radio-inner {
  border-color: theme('colors.colorPrimary');
}

.ant-tabs-tab-btn {
  text-transform: capitalize;
}
