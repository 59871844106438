.quantity-picker {
  display: inline-block;
  border-radius: 3px;
  vertical-align: middle;
  width: fit-content;
}
.quantity-input:focus {
  background: red;
}

.quantity-modifier,
.quantity-display {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}

.quantity-modifier {
  height: 100%;
  width: 1.5rem;
  font-size: 1rem;
  color: #888;
  border-radius: 0.5rem;
  border: 0 solid #dbdbdb;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  padding: 0;
  padding-bottom: 0.1rem;
}
.quantity-modifier:hover {
  background: #dadada;
  color: #555555;
}

.quantity-modifier:focus {
  outline: 0;
}

.left-modifier {
  border-radius: 3px 0 0 3px;
}

.mod-disable {
  color: #e0e0e0;
}

.mod-disable:hover {
  cursor: not-allowed;
  background-color: #f3f3f3 !important;
  color: #e0e0e0;
}

.right-modifier {
  border-radius: 0 3px 3px 0;
}

.quantity-display {
  width: 2rem;
  font-size: 1rem;
  border: 0;
  border-top: 0 solid #dbdbdb;
  border-bottom: 0 solid #dbdbdb;
  text-align: center;
  border-radius: 0.5rem;
  cursor: default;
  pointer-events: none;
}
