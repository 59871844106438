button.ant-switch {
    background-color: lightgray !important;
}

button.ant-switch.ant-switch-checked {
    background-color: theme('colors.colorPrimary') !important;
}

.ant-drawer-body::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}