.nav-menu {
  background-color: white;
  width: 350px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
  z-index: 20;
}

.nav-menu.active {
  right: 0;
  transition: 350ms;
}
